const ID_TOKEN_KEY = 'id_token'
const ACCESS_TOKEN_KEY = 'access_token'
const REFRESH_TOKEN_KEY = 'refresh_token'
const LocalStorageService = (function () {
  var _service

  function _getService () {
    if (!_service) {
      _service = this
      return _service
    }
    return _service
  }

  function _setToken (tokenObj) {
     // console.log(tokenObj, getParameterByName('user'), getParameterByName('error'), getParameterByName(ACCESS_TOKEN_KEY), getParameterByName(REFRESH_TOKEN_KEY))

    if (tokenObj !== undefined) {
      localStorage.setItem(ACCESS_TOKEN_KEY, tokenObj.access_token)
      localStorage.setItem(REFRESH_TOKEN_KEY, tokenObj.refresh_token)
    } else {
      const errorMsg = getParameterByName('error')
      if (errorMsg !== null) {
        return
      }
      const userdata = getParameterByName('user')
      if (userdata !== null) {
        // console.log(userdata)
        localStorage.setItem('user', userdata)
      }
      const accessToken = getParameterByName(ACCESS_TOKEN_KEY)
      if (accessToken !== null) localStorage.setItem(ACCESS_TOKEN_KEY, accessToken)
      // localStorage.setItem(ACCESS_TOKEN_KEY, getParameterByName(ACCESS_TOKEN_KEY))
      const refreshToken = getParameterByName(REFRESH_TOKEN_KEY)
      if (refreshToken !== null) { localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken) }
      // localStorage.setItem(REFRESH_TOKEN_KEY, getParameterByName(REFRESH_TOKEN_KEY))
    }
  }

  function _getErrorMessage () {
    return getParameterByName('error')
  }

  function _getAccessToken () {
    return localStorage.getItem(ACCESS_TOKEN_KEY)
  }

  function _getRefreshToken () {
    return localStorage.getItem(REFRESH_TOKEN_KEY)
  }

  function _clearToken () {
    localStorage.removeItem(ACCESS_TOKEN_KEY)
    localStorage.removeItem(REFRESH_TOKEN_KEY)
    localStorage.removeItem('user')
  }

  function _clearChannel () {
    localStorage.removeItem('channel')
  }

  function _getUsername () {
    return localStorage.getItem('user')
  }

  function _setUsername (user) {
    return localStorage.setItem('user', user)
  }

  function _setChannel (channel) {
    localStorage.setItem('channel', channel)
  }

  function _getChannel () {
    return localStorage.getItem('channel')
  }

  function _getDarkTheme () {
    return localStorage.getItem('theme')
  }

  function _setDarkTheme (val) {
    localStorage.setItem('theme', val)
  }

  function _getBarColor () {
    return localStorage.getItem('barColor')
  }

  function _setBarColor (val) {
    localStorage.setItem('barColor', val)
  }

  function _getBarImage () {
    return localStorage.getItem('barImage')
  }

  function _setBarImage (val) {
    localStorage.setItem('barImage', val)
  }

  function _getPrimary () {
    return localStorage.getItem('primary')
  }

  function _setPrimary (val) {
    localStorage.setItem('primary', val)
  }

  function _getAvatar () {
    return localStorage.getItem('avatar')
  }

  function _setAvatar (val) {
    localStorage.setItem('avatar', val)
  }

  return {
    getService: _getService,
    setToken: _setToken,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    clearToken: _clearToken,
    getUsername: _getUsername,
    setUsername: _setUsername,
    setChannel: _setChannel,
    getChannel: _getChannel,
    clearChannel: _clearChannel,
    getDarkTheme: _getDarkTheme,
    setDarkTheme: _setDarkTheme,
    getBarColor: _getBarColor,
    setBarColor: _setBarColor,
    getBarImage: _getBarImage,
    setBarImage: _setBarImage,
    getPrimary: _getPrimary,
    setPrimary: _setPrimary,
    getAvatar: _getAvatar,
    setAvatar: _setAvatar,
    getErrorMessage: _getErrorMessage,
  }
})()
export default LocalStorageService

/* export function login(obj) {
 const url = `${BASE_URL}/authentication`
 return axios.post(url, obj, {withCredentials: false}).then(response => response)
 } */
export function handlelogin (id) {
  const url = `/v1/authentication/${id}`
  window.location.href = url

  // return axios.get(url,{withCredentials: false}).then(response => response)
}

export function logout () {
  clearIdToken()
  clearAccessToken()
}

/* export function logout(obj) {
 const url = `${BASE_URL}`
 return axios.post(url, obj).then(response => response)
 } */
export function requireAuth (to, from, next) {
  if (!isLoggedIn()) {
    next({
      path: '/cloud/',
      query: { redirect: to.fullPath },
    })
  } else {
    next()
  }
}

export function getIdToken () {
  return localStorage.getItem(ID_TOKEN_KEY)
}

function clearIdToken () {
  localStorage.removeItem(ID_TOKEN_KEY)
}

function clearAccessToken () {
  localStorage.removeItem(ACCESS_TOKEN_KEY)
}

export function getAccessToken () {
  return localStorage.getItem(ACCESS_TOKEN_KEY)
}

// Helper function that will allow us to extract the access_token and id_token
function getParameterByName (name) {
  const match = RegExp('[#&]' + name + '=([^&]*)').exec(window.location.hash)
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '))
}

// Get and store access_token in local storage
export function setAccessToken () {
  const accessToken = getParameterByName('access_token')
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken)
}

// Get and store id_token in local storage
export function setIdToken () {
  const idToken = getParameterByName('id_token')
  localStorage.setItem(ID_TOKEN_KEY, idToken)
}

export function isLoggedIn () {
  // return true;
  const idToken = getIdToken()
  const accessToken = getAccessToken()
  // return !!idToken/* && !isTokenExpired(idToken)*/;
  return !!idToken || !!accessToken
}

/* function getTokenExpirationDate(encodedToken) {
 const token = decode(encodedToken);
 if (!token.exp) { return null; }

 const date = new Date(0);
 date.setUTCSeconds(token.exp);

 return date;
 }

 function isTokenExpired(token) {
 const expirationDate = getTokenExpirationDate(token);
 return expirationDate < new Date();
 } */

export async function isAuthenticated () {
  return !!(await this.getAccessToken()) || !!(await this.getIdToken())
}
