import Vue from 'vue'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import VueSweetalert2 from 'vue-sweetalert2'
import VueSSE from 'vue-sse'
import Chartkick from 'vue-chartkick'
import Chart from 'chart.js'
import '@mdi/font/css/materialdesignicons.min.css'
import 'roboto-npm-webfont/full/roboto-light.css'

Vue.prototype.$http = axios
// axios.defaults.baseURL = 'https://oauth2.rnd.com:8080'
axios.defaults.baseURL = ''
axios.defaults.headers.get.Accept = 'application/json'
Vue.config.productionTip = false

Vue.use(VueSweetalert2)
Vue.use(VueSSE)
Vue.use(Chartkick.use(Chart))

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
