<template>
  <base-material-card
    id="coloured-line"
    class="v-card--material-log-table px-4 py-3"
    v-bind="$attrs"
    hover-reveal
    inline
    v-on="$listeners"
  >
    <template v-slot:after-heading>
      <div class="display-2 font-weight-light mt-2 text-h3">
        {{ title }}
      </div>
    </template>

    <v-row class="row-date-picker px-3">
      <slot v-if="datePicker">
        <v-col
          cols="6"
          md="3"
          sm="6"
          lg="3"
        >
          <v-menu
            v-model="startMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                label="Start Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                hint="Choose dates between 60 days"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="startDate"
              no-title
              :allowed-dates="allowDates"
              @change="validateDate"
            />
          </v-menu>
        </v-col>

        <v-col
          cols="6"
          md="3"
          sm="6"
          lg="3"
        >
          <v-menu
            v-model="endMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDate"
                label="End Date"
                prepend-icon="mdi-calendar"
                hint="Choose dates between 60 days"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="endDate"
              no-title
              :allowed-dates="allowDates"
              @change="validateDate"
            />
          </v-menu>
        </v-col>
      </slot>

      <v-spacer />

      <v-text-field
        v-if="!advancedSearch"
        v-model="search"
        append-icon="mdi-magnify"
        class="ml-auto"
        label="Search"
        hide-details
        single-line
        style="max-width: 250px; padding-bottom: 12px;"
      />
    </v-row>

    <v-form
      v-if="advancedSearch"
      @submit.prevent="searchSubmit(advSearch)"
    >
      <v-row class="row-search px-3">
        <v-col
          cols="6"
          md="1"
          sm="6"
          lg="1"
        >
          <span class="text-h4 text--secondary">Search:</span>
        </v-col>

        <v-col
          v-for="item in advSearch"
          :key="item.key"
          cols="6"
          md="2"
          lg="2"
          sm="6"
        >
          <v-text-field
            v-model="item.value"
            :label="item.name"
            clearable
          />
        </v-col>

        <!--        <v-col
          cols="12"
          md="10"
          sm="6"
          lg="10"
        >
          <v-row>
          </v-row>
        </v-col>-->
        <v-col
          cols="6"
          md="1"
          lg="1"
          sm="6"
        >
          <v-btn
            color="primary"
            type="submit"
          >
            Apply
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-divider class="mt-1" />

    <v-data-table
      :headers="headers"
      :items="data"
      :search.sync="search"
      :loading="loading"
      :options.sync="options"
      :server-items-length="totalItems"
      :sort-by="sortBy"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 15, 20]
      }"
    >
      <template>
        <v-data-footer
          :options="options"
          items-per-page-text="$vuetify.dataTable.itemsPerPageText"
          @update:options="updateOptions"
        />
      </template>

      <!--      <template v-slot:no-data>
        <v-alert
          :value="true"
          color="error"
          icon="warning"
        >
          Sorry, nothing to display here :(
        </v-alert>
      </template>
      <template v-slot:no-results>
        <v-alert>
          Your search for "{{ search }}" found no results.
        </v-alert>
      </template>-->
    </v-data-table>
  </base-material-card>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'MaterialChartCard',

    inheritAttrs: false,

    props: {
      url: {
        type: String,
        default: undefined,
      },

      title: {
        type: String,
        default: '',
      },

      datePicker: {
        type: Boolean,
        default: false,
      },

      advancedSearch: {
        type: Boolean,
        default: true,
      },

      headers: {
        type: Array,
        default: () => ([
          { text: 'Time', value: 'CreatedAt' },
          { text: 'Client IP', value: 'clientIP' },
          { text: 'User', value: 'CorpUser.username' },
          { text: 'Event Info', value: 'actionType' },
          { text: 'Application', value: 'AppClient.appName' },
          { text: 'Key Name', value: 'keyName' },
          { text: 'Status', value: 'msg' },
        ]),
      },
    },

    data: function () {
      return {
        data: [],
        pagination: {},
        totalItems: 0,
        options: {},
        loading: false,

        sort: '',
        sortBy: ['CreatedAt'],
        sortConvert: {
          CreatedAt: 'created_at',
          clientIP: 'client_ip',
          'CloudUser.username': 'master',
          'CorpUser.username': 'user',
          actionType: 'action_type',
          'AppClient.appName': 'app',
          keyName: 'keyname',
          msg: 'msg',
        },

        // Date picker
        startMenu: false,
        endMenu: false,
        startDate: new Date(Date.now() - 864e5 * 7).toISOString().substr(0, 10),
        endDate: new Date().toISOString().substr(0, 10),

        // search
        advSearch: [
          { name: 'Cient IP', key: 'client_ip', value: null },
          { name: 'User', key: 'user', value: null },
          { name: 'Event', key: 'action_type', value: null },
          { name: 'Key Name', key: 'keyname', value: null },
          { name: 'Status', key: 'msg', value: null },
        ],
        searchURL: '',
        search: undefined,
      }
    },

    watch: {
      options: {
        handler (newOps, originOps) {
          // console.log('newOps', newOps, originOps, this.options)

          // sort
          if (newOps.sortBy[0] !== undefined) {
            this.sort = `${this.sortConvert[newOps.sortBy[0]]} ${newOps.sortDesc[0] ? 'asc' : 'desc'}`
          } else {
            this.sort = ''
          }
          this.loadItems()
        },
        deep: true,
      },

      search (filter) {
        // console.log('search ', filter)
        if (filter) {
          this.data = this.data.filter(item => {
            return Object.values(item)
              .join(',')
              .toLowerCase()
              .includes(filter)
          })
        } else {
          this.loadItems()
        }
      },
    },

    /*
    mounted () { this.loadItems() },
*/

    methods: {
      updateOptions () { alert(1) },

      async loadItems () {
        this.loading = true
        var url = ''
        if (this.datePicker) {
          url = `${this.url}/${this.startDate}/${this.endDate}?limit=${this.options.itemsPerPage}&page=${this.options.page - 1}&sort=${this.sort}${this.searchURL}`
        } else {
          url = `${this.url}?limit=${this.options.itemsPerPage}&page=${this.options.page - 1}&sort=${this.sort}${this.searchURL}`
        }
        await axios.get(url, { withCredentials: false })
          .then((response) => {
            // console.log('response logs', response)
            if (response.data.data.rows) {
              this.data = response.data.data.rows
              this.totalItems = response.data.data.total_rows
            } else {
              this.data = []
            }
          }).catch(e => {
            if (e.response === undefined) {
              this.show_message('error', e.toString())
            } else if (!e.response.data.success) {
              if (e.response.data.validations) {
                this.show_message('error', e.response.data.validations[0].message)
              } else {
                this.show_message('error', e.response.data.message)
              }
            } else {
              this.show_message('error', 'api service not available!')
            }
          }).finally(() => (this.loading = false)) // set loading to false when request finish
      },

      allowDates (val) {
        const date = new Date(val)
        const current = new Date()
        return date.getTime() <= current.getTime()
      },

      validateDate () {
        const timeConvert = 1000 * 3600 * 24
        var sDate = new Date(this.startDate)
        var eDate = new Date(this.endDate)
        var ifStartChanged = this.startMenu
        if (eDate < sDate) {
          this.endDate = sDate.toISOString().substr(0, 10)
          this.startDate = eDate.toISOString().substr(0, 10)
          const date = sDate
          sDate = eDate
          eDate = date
          ifStartChanged = !ifStartChanged
        }
        const range = (eDate - sDate) / timeConvert
        if (range > 60) {
          if (ifStartChanged) {
            eDate.setTime(sDate.getTime() + 60 * timeConvert)
            this.endDate = eDate.toISOString().substr(0, 10)
          } else {
            sDate.setTime(eDate.getTime() - 60 * timeConvert)
            this.startDate = sDate.toISOString().substr(0, 10)
          }
        }
        this.loadItems()
        this.startMenu = false
        this.endMenu = false
      },

      searchSubmit (search) {
        this.searchURL = ''
        for (var item of search) {
          if (item.value) this.searchURL += '&' + item.key + '=' + item.value
        }
        this.loadItems()
      },

      show_message (type, text) {
        // type = 'success', 'info', 'error' ... color string
        this.$swal.fire({
          icon: type,
          title: type,
          text: text,
          timer: 5000,
        })
      },
    },
  }
</script>

<style lang="sass">

.row-date-picker
  /* max-height: 70px
  padding-left: 12px
  .col-md-3, col-12
    padding: 5px !important
    max-height: 60px

.row-search
  display: flex
  flex-wrap: wrap
  align-content: center

  .col-md-1,.col-12
    padding: 5px !important
    display: flex !important
    flex-wrap: wrap
    align-content: center
    justify-content: center

    .theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined)
      background-color: #fff
      margin-right: 0px
      margin-bottom: 0px
      max-width: 80px

  .col-sm-6, .col-md-10, .col-lg-10, .col-12
    padding: 5px !important
    display: flex !important
    flex-wrap: wrap
    align-content: center
    justify-content: center

    .row
      /* max-height: 65px
      .col-sm-6, .col-md-2, .col-lg-2, .col-6
        padding-left: 12px !important
        padding-right: 12px !important
        margin: 0 !important
        max-height: 60px

</style>
