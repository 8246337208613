var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-material-card',_vm._g(_vm._b({staticClass:"v-card--material-log-table px-4 py-3",attrs:{"id":"coloured-line","hover-reveal":"","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light mt-2 text-h3"},[_vm._v(" "+_vm._s(_vm.title)+" ")])]},proxy:true}])},'base-material-card',_vm.$attrs,false),_vm.$listeners),[_c('v-row',{staticClass:"row-date-picker px-3"},[(_vm.datePicker)?_vm._t("default",[_c('v-col',{attrs:{"cols":"6","md":"3","sm":"6","lg":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start Date","prepend-icon":"mdi-calendar","readonly":"","hint":"Choose dates between 60 days"},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},'v-text-field',attrs,false),on))]}}],null,false,992253922),model:{value:(_vm.startMenu),callback:function ($$v) {_vm.startMenu=$$v},expression:"startMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","allowed-dates":_vm.allowDates},on:{"change":_vm.validateDate},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_c('v-col',{attrs:{"cols":"6","md":"3","sm":"6","lg":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"End Date","prepend-icon":"mdi-calendar","hint":"Choose dates between 60 days","readonly":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},'v-text-field',attrs,false),on))]}}],null,false,3228051490),model:{value:(_vm.endMenu),callback:function ($$v) {_vm.endMenu=$$v},expression:"endMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","allowed-dates":_vm.allowDates},on:{"change":_vm.validateDate},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1)]):_vm._e(),_c('v-spacer'),(!_vm.advancedSearch)?_c('v-text-field',{staticClass:"ml-auto",staticStyle:{"max-width":"250px","padding-bottom":"12px"},attrs:{"append-icon":"mdi-magnify","label":"Search","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],2),(_vm.advancedSearch)?_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.searchSubmit(_vm.advSearch)}}},[_c('v-row',{staticClass:"row-search px-3"},[_c('v-col',{attrs:{"cols":"6","md":"1","sm":"6","lg":"1"}},[_c('span',{staticClass:"text-h4 text--secondary"},[_vm._v("Search:")])]),_vm._l((_vm.advSearch),function(item){return _c('v-col',{key:item.key,attrs:{"cols":"6","md":"2","lg":"2","sm":"6"}},[_c('v-text-field',{attrs:{"label":item.name,"clearable":""},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})],1)}),_c('v-col',{attrs:{"cols":"6","md":"1","lg":"1","sm":"6"}},[_c('v-btn',{attrs:{"color":"primary","type":"submit"}},[_vm._v(" Apply ")])],1)],2)],1):_vm._e(),_c('v-divider',{staticClass:"mt-1"}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"search":_vm.search,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalItems,"sort-by":_vm.sortBy,"footer-props":{
      itemsPerPageOptions: [5, 10, 15, 20]
    }},on:{"update:search":function($event){_vm.search=$event},"update:options":function($event){_vm.options=$event}}},[[_c('v-data-footer',{attrs:{"options":_vm.options,"items-per-page-text":"$vuetify.dataTable.itemsPerPageText"},on:{"update:options":_vm.updateOptions}})]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }