import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import LocalStorageService from './utils/auth'
// import { getUsers } from './utils/user-api'

Vue.use(Vuex)
const localStorageService = LocalStorageService.getService()

export default new Vuex.Store({
  user: null,

  state: {
    status: '',
    barColor: localStorageService.getBarColor() || 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: localStorageService.getBarImage() || 'sidebar-12.jpg',
    drawer: null,
    token: localStorageService.getAccessToken() || '',
    user: localStorageService.getUsername() || '',
    primary: localStorageService.getPrimary() || '#E91E63',
    pickedChild: null,
  },

  mutations: {
    showMessage (state, payload) {
      state.content = payload.content
      state.color = payload.color
    },
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },

    SET_DRAWER (state, payload) {
      state.drawer = payload
    },

    SET_SCRIM (state, payload) {
      // console.log('set scrim')
      state.barColor = payload
    },

    SET_PICKED_CHILD (state, childName) {
      state.pickedChild = childName
    },

    SET_USER (state, newUser) {
      state.user = newUser
    },

    auth_request (state) {
      state.status = 'loading'
    },

    auth_success (state, token, user) {
      // console.log('state ', state)
      // console.log('auth_success ', state, token, user, localStorageService.getUsername())
      state.status = 'success'
      state.token = token
      state.user = localStorageService.getUsername()
      // Add the following line:
      if (token) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`
      }
    },

    auth_error (state) {
      state.status = 'error'
    },

    logout (state) {
      state.status = ''
      state.token = ''
    },
  },

  actions: {
    register ({ commit }, userData) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios.post('/v1/user/register', {
            username: userData.username,
            fullName: userData.fullName,
            email: userData.email,
            keyName: userData.keyName,
            regType: userData.regType,
          })
          .then(response => {
            // alert(response.data)
            const token = response.data.access_token
            // const user = response.data.username
            // console.log(response)
            // storing jwt in localStorage. https cookie is safer place to store
            // localStorage.setItem('token', token)
            // localStorage.setItem('user', user)
            axios.defaults.headers.common.Authorization = 'Bearer ' + token
            // mutation to change state properties to the values passed along
            commit('auth_success', token, localStorageService.getUsername())
            resolve(response)
          })
          .catch(err => {
            // console.log('login error')
            commit('auth_error')
            localStorage.removeItem('token')
            reject(err)
          })
      })
    },

    refreshtoken ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/api/token/refresh/${data}`)
          .then(response => {
            localStorageService.setToken(response.data)
            const token = response.data.access_token
            // console.log('refreshdata: ', response.data)
            // localStorage.setItem('token', token)
            axios.defaults.headers.common.Authorization = 'Bearer ' + token
            commit('auth_success', localStorageService.getAccessToken(), localStorageService.getUsername())
            resolve(response)
          })
          .catch(error => {
            // console.log('refresh token error')
            commit('logout')
            localStorageService.clearToken()
            // localStorage.removeItem('token')
            // console.log(error)
            reject(error)
          })
      })
    },

    loginCallback ({ commit }, data) {
      commit('auth_success', data.token, data.user)
    },

    login ({ commit }, data) {
      return new Promise(() => {
        commit('auth_request')
        // localStorageService.setUserName(data)
        // window.location.href = `https://oauth2.rnd.com:8080/v1/dev/authentication/${data}`
        window.location.href = `/v1/authentication/${data}`
        // const url = `${BASE_URL}/authentication?${data}`;
        // window.location.href = url;
        // handlelogin(data)
      })
    },

    logout ({ commit }) {
      return new Promise((resolve) => {
        axios.get('/v1/logout').then(response => {}).catch(() => {})
        commit('logout')
        // localStorage.removeItem('access_token')
        localStorage.clear()
        delete axios.defaults.headers.common.Authorization
        resolve()
      })
    },
  },

  getters: {
    isLoggedIn: state => !!state.token,
    /*    isAdmin (state) {
      const userData = JSON.parse(state.user)
      return userData.roleType === 3
    }, */
    authStatus: state => state.status,
    currentUser (state) {
      const userData = JSON.parse(state.user)
      return userData
    },
    currentTheme: state => localStorageService.getDarkTheme(),
    primary: state => state.primary,
    getChild (state) {
      // const userData = JSON.parse(state.user)
      return state.pickedChild // || userData.username
    },
    master (state) {
      const userData = JSON.parse(state.user)
      return userData.username.split('@')[0]
    },
  },
})
