import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/pages',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: 'Login',
          path: 'login',
          component: () => import('@/views/pages/Login'),
          meta: { checkIfLoggedIn: true },
        },
        {
          name: 'Register',
          path: 'register',
          component: () => import('@/views/pages/register'),
          meta: { checkIfLoggedIn: true },
        },
      ],
    },
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Master
        {
          name: 'Profile',
          path: 'master/profile',
          component: () => import('@/views/dashboard/master/Profile'),
          meta: {
            requiresAuth: true,
            // requiresAdmin: true,
          },
        },
        {
          name: 'Keys',
          path: 'master/keys',
          component: () => import('@/views/dashboard/master/Keys'),
          meta: {
            requiresAuth: true,
            // requiresAdmin: true,
          },
        },
        // Admin Dashboard
        {
          name: 'Dashboard',
          path: '',
          component: () => import('@/views/dashboard/Dashboard'),
           meta: {
            requiresAuth: true,
            // requiresAdmin: true,
          },
        },
        // Statistic
        {
          name: 'Apps statistic',
          path: 'stats/apps',
          component: () => import('@/views/dashboard/statistic/Apps'),
          meta: { requiresAuth: true },
        },
        {
          name: 'Keys statistic',
          path: 'stats/keys',
          component: () => import('@/views/dashboard/statistic/Keys'),
          meta: { requiresAuth: true },
        },
        // Configurations
        {
          name: 'Apps Configurations',
          path: 'configs/apps',
          component: () => import('@/views/dashboard/configurations/Apps'),
          meta: { requiresAuth: true },
        },
        {
          name: 'Keys Configurations',
          path: 'configs/keys',
          component: () => import('@/views/dashboard/configurations/Keys'),
          meta: { requiresAuth: true },
        },
        {
          name: 'Users Configurations',
          path: 'configs/users',
          component: () => import('@/views/dashboard/configurations/Users'),
          meta: { requiresAuth: true },
        },
        {
          name: 'Permission Configurations',
          path: 'configs/permission',
          component: () => import('@/views/dashboard/configurations/Permission'),
          meta: { requiresAuth: true },
        },
      ],
    },
    {
      path: '/callback',
      component: () => import('@/components/callback'),
    },
    {
      path: '*',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: '404 Error',
          path: '',
          component: () => import('@/views/pages/Error'),
        },
      ],
    },
  ],
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  // console.log('something wrong here?', to)
  if (requiresAuth) {
    // console.log('requiresAuth', requiresAuth, store.getters.isLoggedIn)
    if (!store.getters.isLoggedIn) {
      next({
        path: '/pages/login',
        query: { redirect: to.fullPath },
      })
    } else {
      // console.log('logged in', to)
      next()
    }
  } else {
    // console.log('!requiresAuth', requiresAuth)
    const checkIfLoggedIn = to.matched.some(record => record.meta.checkIfLoggedIn)
    if (checkIfLoggedIn) {
      // console.log('checkIfLoggedIn', checkIfLoggedIn)
      if (store.getters.isLoggedIn) {
        // console.log('logged in', to)
        next({
          path: '/',
          query: { redirect: to.fullPath },
        })
      } else {
        // console.log('!logged in', to)
        next()
      }
    } else {
       // console.log('!checkIfLoggedIn', checkIfLoggedIn)
      next()
    }
  }
})

export default router
