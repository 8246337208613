<template>
  <base-material-card
    id="coloured-line"
    class="v-card--material-chart px-4 py-3"
    v-bind="$attrs"
    hover-reveal
    v-on="$listeners"
  >
    <template v-slot:after-heading>
      <div>
        <span class="display-2 text--primary mt-2 text-h3">
          {{ title }}
        </span>
        <v-spacer />
        <span class="text--secondary font-weight-light">Total: {{ total }}</span>
      </div>
    </template>

    <template>
      <column-chart
        :data="data"
        :dataset="{borderWidth: 1, }"
        :library="chartOptions"
        style="max-height: 150px;"
      />
    </template>
    <v-divider class="mb-3" />
    <template>
      <div class="caption text--secondary grey--text">
        <v-icon small>
          {{ item.subIcon || 'mdi-clock' }}
        </v-icon>
        From {{ dayStart }} to {{ dayEnd }}
      </div>
    </template>
  </base-material-card>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'MaterialChartCard',

    inheritAttrs: false,

    props: {
      title: {
        type: String,
        default: '',
      },

      item: {
        type: Object,
        default: () => ({}),
      },

      /*      type: {
        type: String,
        required: true,
        validator: v => ['Bar', 'Line', 'Pie'].includes(v),
      }, */
    },

    data () {
      return {
        data: [],
        total: 0,
        loading: false,
        dayStart: '',
        dayEnd: '',
        chartOptions: {
          layout: {
            padding: { left: 10, right: 5, top: 5, bottom: 2 },
          },
          scales: {
            xAxes: [{
              display: false, // this will remove only the label
            }],
          },
        },
      }
    },

    created () { this.initialize() },

    methods: {
      initialize () {
        this.loadItems()
      },

      async loadItems () {
        this.loading = true
        await axios.get(this.item.url, { withCredentials: false })
          .then((response) => {
            if (response.data.data) {
              this.data = response.data.data[this.item.keyword]
              this.dayStart = new Date(Date.now() - 864e5 * 30).toISOString().substr(0, 10)
              this.dayEnd = new Date(Date.now()).toISOString().substr(0, 10)
              // console.log(this.data)
              // console.log(response.data)
              if (response.data.data.Total) {
                this.total = response.data.data.Total
              } else {
                this.total = []
                for (var i in this.data) {
                  this.total[i] = this.data[i].name + ': ' + this.data[i].Total
                  // console.log('total', this.total.toString())
                }
                this.total = this.total.join(', ')
              }
            } else {
              this.data = {}
              const day1 = new Date(Date.now() - 864e5 * 30).toISOString().substr(0, 10)
              // const day2 = new Date(Date.now() - 864e5 * 3).toISOString().substr(0, 10)
              const day3 = new Date().toISOString().substr(0, 10)
              this.data[day1] = 0
              // this.data[day2] = 0
              this.data[day3] = 0
              // console.log('this.data', this.data)
              this.total = 0
            }
          }).catch(e => {
            if (e.response === undefined) {
              this.show_message('error', e.toString())
            } else if (!e.response.data.success) {
              if (e.response.data.validations) {
                this.show_message('error', e.response.data.validations[0].message)
              } else {
                this.show_message('error', e.response.data.message)
              }
            } else {
              this.show_message('error', 'api service not available!')
            }
          }).finally(() => (this.loading = false)) // set loading to false when request finish
      },

      show_message (type, text) {
        // type = 'success', 'info', 'error' ... color string
        this.$swal.fire({
          icon: type,
          title: type,
          text: text,
          timer: 5000,
        })
      },
    },
  }
</script>

<style lang="sass">
  .v-card--material-chart
    p
      color: #999

    .v-card--material__heading
      max-height: 185px

      .ct-label
        color: inherit
        opacity: .7
        font-size: 0.975rem
        font-weight: 100

      .ct-grid
        stroke: rgba(255, 255, 255, 0.2)

      .ct-series-a .ct-point,
      .ct-series-a .ct-line,
      .ct-series-a .ct-bar,
      .ct-series-a .ct-slice-donut
          stroke: rgba(255,255,255,.8)

      .ct-series-a .ct-slice-pie,
      .ct-series-a .ct-area
          fill: rgba(255,255,255,.4)
</style>
