<template>
  <base-material-card
    class="v-card--material-statistic px-4 py-3"
    v-bind="$attrs"
    hover-reveal
    v-on="$listeners"
  >
    <template v-slot:after-heading>
      <div
        class="display-2 font-weight-light mt-2 text-h3"
        v-text="title"
      />
    </template>

    <v-row>
      <v-col
        cols="12"
        md="4"
        sm="4"
        lg="4"
        class="mt-5"
      >
        <v-menu
          v-model="startMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="startDate"
              label="Start Date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              hint="Choose dates between 60 days"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="startDate"
            no-title
            :allowed-dates="allowDates"
            @change="validateDate"
          />
        </v-menu>

        <v-menu
          v-model="endMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="endDate"
              label="End Date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              hint="Choose dates between 60 days"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="endDate"
            no-title
            :allowed-dates="allowDates"
            @change="validateDate"
          />
        </v-menu>

        <slot name="selection" />

        <slot
          name="total"
          class="px-2"
        >
          <span class="display-1 text--secondary">
            Total: {{ total }}
          </span>
          <!--          <span
            v-for="item in data"
            :key="item.name"
            class="display-1 text&#45;&#45;secondary"
          >
            {{ item.name }} : {{ item.Total }}.
          </span>-->
        </slot>
      </v-col>

      <v-col
        cols="12"
        md="8"
        sm="8"
        lg="8"
        align-self="center"
      >
        <template>
          <column-chart
            :data="data"
            :curve="false"
            :dataset="{borderWidth: 1}"
            style="max-height: 250px;"
          />
        </template>
      </v-col>
    </v-row>
  </base-material-card>
</template>

<script>
  import Card from './Card'
  import axios from 'axios'

  export default {
    name: 'MaterialStatistic',

    inheritAttrs: false,

    props: {
      ...Card.props,
      url: {
        type: String,
        default: '',
      },

      title: {
        type: String,
        default: '',
      },

      keyword: {
        type: String,
        default: '',
      },
    },

    data: function () {
      return {
        startMenu: false,
        endMenu: false,
        startDate: new Date(Date.now() - 864e5 * 7).toISOString().substr(0, 10),
        endDate: new Date().toISOString().substr(0, 10),
        data: [],
        total: 0,
      }
    },

    mounted () {
      this.loadItems()
    },

    methods: {
      async loadItems () {
        await axios.get(`${this.url}/${this.startDate}/${this.endDate}`, { withCredentials: false })
          .then((response) => {
            if (response.data.data) {
              this.data = response.data.data[this.keyword]

              if (response.data.data.Total) {
                this.total = response.data.data.Total
              } else {
                this.total = []
                for (var i in this.data) {
                  this.total[i] = this.data[i].name + ': ' + this.data[i].Total
                  // console.log('total', this.total.toString())
                }
                this.total = this.total.join(', ')
              }
            } else {
              this.data = {}
              /*              const day1 = new Date(Date.now() - 864e5 * 7).toISOString().substr(0, 10)
              const day2 = new Date(Date.now() - 864e5 * 3).toISOString().substr(0, 10)
              const day3 = new Date().toISOString().substr(0, 10)
              this.data[day1] = 0
              this.data[day2] = 0
              this.data[day3] = 0 */
              this.data[this.startDate] = 0
              this.data[this.endDate] = 0
              this.total = 0
              // console.log('this.data', this.data)
            }

            // console.log('stats chart response', response)
            // console.log('stats chart data', this.data)
          }).catch(e => {
            if (e.response === undefined) {
              this.show_message('error', e.toString())
            } else if (!e.response.data.success) {
              if (e.response.data.validations) {
                this.show_message('error', e.response.data.validations[0].message)
              } else {
                this.show_message('error', e.response.data.message)
              }
            } else {
              this.show_message('error', 'api service not available!')
            }
          }).finally(() => (this.loading = false)) // set loading to false when request finish
      },

      allowDates (val) {
        const date = new Date(val)
        const current = new Date()
        return date.getTime() <= current.getTime()
      },

      validateDate () {
        const timeConvert = 1000 * 3600 * 24
        var sDate = new Date(this.startDate)
        var eDate = new Date(this.endDate)
        var ifStartChanged = this.startMenu
        if (eDate < sDate) {
          this.endDate = sDate.toISOString().substr(0, 10)
          this.startDate = eDate.toISOString().substr(0, 10)
          const date = sDate
          sDate = eDate
          eDate = date
          ifStartChanged = !ifStartChanged
        }
        const range = (eDate - sDate) / timeConvert
        if (range > 60) {
          if (ifStartChanged) {
            eDate.setTime(sDate.getTime() + 60 * timeConvert)
            this.endDate = eDate.toISOString().substr(0, 10)
          } else {
            sDate.setTime(eDate.getTime() - 60 * timeConvert)
            this.startDate = sDate.toISOString().substr(0, 10)
          }
        }
        this.loadItems()
        this.startMenu = false
        this.endMenu = false
      },

      show_message (type, text) {
        // type = 'success', 'info', 'error' ... color string
        this.$swal.fire({
          icon: type,
          title: type,
          text: text,
          timer: 5000,
        })
      },
    },
  }
</script>
