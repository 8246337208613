<template>
  <router-view />
</template>

<script>
  import axios from 'axios'
  import LocalStorageService from './utils/auth'

  const localStorageService = LocalStorageService.getService()

  export default {
    name: 'App',

    created: function () {
      this.$vuetify.theme.dark = this.$store.getters.currentTheme
      // Add a request interceptor
      axios.interceptors.request.use(
        config => {
          const token = localStorageService.getAccessToken()
          if (token) {
            config.headers.Authorization = 'Bearer ' + token
          }
          // config.headers['Content-Type'] = 'application/json';
          return config
        },
        error => {
          Promise.reject(error)
        })
      // Add a response interceptor
      this.$http.interceptors.response.use((response) => {
        return response
      }, (error) => {
        // Return any error which is not due to authentication back to the calling service
        if (error.response.status !== 401) {
          if (error.response.status === 429) {
            return Promise.reject(new Error('Limit exceeded'))
          }
          return new Promise((resolve, reject) => {
            reject(error)
          })
        }

        // if you ever get an unauthorized, logout the user
        this.$store.dispatch('logout')
          .then(() => {
            this.$router.push('/pages/login')
          })
        return Promise.reject(new Error('Token invalid. Logout'))
        /* // Logout user if token refresh didn't work or user is disabled
        if (error.config.url.includes('/v1/api/token/refresh/') || error.response.data.message === 'Account is disabled.') {
          localStorageService.clearToken()
          this.$store.dispatch('logout')
          this.$router.push('/pages/login').catch(() => {

          })

          return new Promise((resolve, reject) => {
            // reject(error)
            this.$store.dispatch('logout')
            // this.$router.push('/pages/login')
          })
        }

        // Try request again with new token
        const data = localStorageService.getRefreshToken()
        // console.log(data, typeof data)
        if (data !== 'undefined' && data !== null) {
          return axios.get(`/v1/api/token/refresh/${data}`)
            .then((token) => {
              localStorageService.setToken(token.data)

              // New request with new token
              const config = error.config
              config.headers.Authorization = `Bearer ${token.data.access_token}`
              return new Promise((resolve, reject) => {
                axios.request(config).then(response => {
                  resolve(response)
                }).catch((error) => {
                  // console.log(error)
                  reject(error)
                })
              })
            })
            .catch(() => {
              // Promise.reject(error)
              this.$store.dispatch('logout')
              // this.$router.push('/pages/login')
            })
        } else {
          this.$store.dispatch('logout')
          // this.$router.push('/pages/login')
          return Promise.reject(error)
        } */
      })
    },

    mounted () {
      const theme = localStorageService.getDarkTheme()
      if (theme) {
        if (theme === 'true') {
          this.$vuetify.theme.dark = true
        } else {
          this.$vuetify.theme.dark = false
        }
      }
    },
  }
</script>
